import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAddWish } from "@/api/hooks/useAddWishList";
import type { ProductDetailRequestParams } from "@/api/hooks/useGetProductDetail";
import { useGetProductDetail } from "@/api/hooks/useGetProductDetail";
import { useGetProductOptions } from "@/api/hooks/useGetProductOptions";
import { Button } from "@/components/common/Button";
import { useAuth } from "@/provider/Auth";
import { getDynamicPath, RouterPath } from "@/routes/path";
import { orderHistorySessionStorage } from "@/utils/storage";

import { CountOptionItem } from "./OptionItem/CountOptionItem";

type Props = ProductDetailRequestParams;

export const OptionSection = ({ productId }: Props) => {
  const { data: detail } = useGetProductDetail({ productId });
  const { data: options } = useGetProductOptions({ productId });

  const [countAsString, setCountAsString] = useState("1");
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (detail) {
      setPrice(detail.price);
    }
  }, [detail]);

  const totalPrice = useMemo(
    () => price * Number(countAsString),
    [price, countAsString]
  );
  const addWish = useAddWish();
  const navigate = useNavigate();
  const authInfo = useAuth();

  const addToWishlist = () => {
    const token = authInfo?.token;

    if (!token) {
      alert("로그인이 필요합니다.");
      navigate(getDynamicPath.login());
      return;
    }

    if (productId) {
      addWish.mutate(
        { productId: Number(productId) },
        {
          onSuccess: () => {
            alert("관심 등록 완료");
          },
        }
      );
    }
  };

  const handleClick = () => {
    if (!authInfo) {
      const isConfirm = window.confirm(
        "로그인이 필요한 메뉴입니다.\n로그인 페이지로 이동하시겠습니까?"
      );
      if (!isConfirm) return;
      return navigate(getDynamicPath.login());
    }

    orderHistorySessionStorage.set({
      id: parseInt(productId),
      count: parseInt(countAsString),
    });

    navigate(RouterPath.order);
  };
  return (
    <Wrapper>
      {options.data.map((option) => (
        <CountOptionItem
          key={option.id} // key prop 추가
          name={option.name}
          value={countAsString}
          onChange={setCountAsString}
        />
      ))}

      <BottomWrapper>
        <PricingWrapper>
          총 결제 금액 <span>{totalPrice}원</span>
        </PricingWrapper>
        <Button theme="black" size="large" onClick={handleClick}>
          나에게 선물하기
        </Button>
        <Button theme="outline" size="large" onClick={addToWishlist}>
          관심 등록
        </Button>
      </BottomWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 12px 30px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BottomWrapper = styled.div`
  padding: 12px 0 0;
`;

const PricingWrapper = styled.div`
  margin-bottom: 20px;
  padding: 18px 20px;
  border-radius: 4px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: #111;

  & span {
    font-size: 20px;
    letter-spacing: -0.02em;
  }
`;
